import { Reference } from "../reference-interface"
import { eventureInfoCards } from "../../service-info-card-interface/used_by/eventure-cards"

export const Eventure : Reference = {
  monitorPic: "/images/refs/eventure/eventure_iphone.png",
  siteName: "Eventure",
  siteDescription: "Az Eventure nem csupán egy rendezvény gyűjtő applikáció. Számtalan módon segíti a rendezvények közötti böngészést, a rendezvényeken való tájékozódást valamint vásárlás.",
  siteUrl: "",
  cards: eventureInfoCards,
  referencePics: [
    "/images/refs/eventure/eventure_screenshot_2.png",
    "/images/refs/eventure/eventure_card.png",
    "/images/refs/eventure/eventure_screenshot_1.png",
  ],
  pageFunctions: [
    "Egyedi design",
    "iOS, Android",
  ],
  reversePageFunctions: false,
}
