import { ServiceInfoCardChildInterface } from "../service-info-card-interface"

const eventure1 : ServiceInfoCardChildInterface = {
  bigImg: false,
  description: "Az EVENTURE rendezvény applikáció innovációs eszközként végig kalauzolja a vendégeket az eseményeken, egyrészt megkönnyítve ezzel az eligazodást, másrészt innovatív jellege okán növelve ezáltal az élményt. ",
  displayXo: true,
  reverse: true,
  title: "Mindig kéznél",
  picture: "/images/refs/eventure/eventure_logo.png",
  boldDescription: ''
}

const eventure2 : ServiceInfoCardChildInterface = {
  bigImg: false,
  description: "A globális technológia fejlődése okán a fejlesztés több ponton hozzájárul a események látogatóinak egyre növekvő igényeinek kielégítéséhez. Megkönnyítve mind a beléptetést a jegy applikációban történő tárolásával.  Cash Less- Prepaid bankkártya kezelés funkció applikációba integrálása eleget tesz a jelenkor készpénzmentességi elvárásainak.",
  displayXo: true,
  reverse: false,
  title: "Mobil élmény",
  picture: "/images/refs/eventure/eventure_2.png",
  boldDescription: ''
}
const eventure3 : ServiceInfoCardChildInterface = {
  bigImg: false,
  description: "Applikációban a tájékozódás béli akadályok megszűnnek, mivel az applikáción online térképes megjelenés vált elérhetővé, a helyszínek felsorolásával, bemutatásával, melyet a látogató, a későbbi fejlesztések érdekében, a felhasználhatóság, érthetőség, kezelhetőség szempontjai alapján értékelni is fog tudni.",
  displayXo: true,
  reverse: false,
  title: "Mobil élmény",
  picture: "/images/refs/eventure/eventure_2.png",
  boldDescription: ''
}
const eventure4 : ServiceInfoCardChildInterface = {
  bigImg: false,
  description: "A fejlesztett  innovatív szolgáltatás lehetőséget biztosít az események során megrendezésére kerülő programokra való applikáción keresztüli jelentkezésre, különféle kuponok tárolására, rendezvényeken való felhasználására, beépítésre.",
  displayXo: true,
  reverse: false,
  title: "Mobil élmény",
  picture: "/images/refs/eventure/eventure_2.png",
  boldDescription: ''
}

export const eventureInfoCards = [
  eventure1,

]

